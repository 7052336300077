import React from 'react';

import week from '../assets/images/2week.svg';
import weekmb from '../assets/images/2weekmb.svg';
import TextDl from '../assets/images/dltext.png';
import Download from '../assets/images/download.svg';
import Footer from '../assets/images/footer.svg';
import Gg from '../assets/images/gg.svg';
import Gu from '../assets/images/gu.png';
import Header from '../assets/images/header.svg';
import HeaderMb from '../assets/images/headermb.svg';
import Ios from '../assets/images/ios.svg';
import Link from '../assets/images/link.svg';
import Prizes from '../assets/images/prizes.svg';
import Prizesmb from '../assets/images/prizesmb.svg';
import Text from '../assets/images/text.png';
import Welcome from '../assets/images/welcome.svg';
import WelcomeMb from '../assets/images/welcomemb.svg';
import Navbar from '../components/Navbar';

function Movement() {
    return (
        <div>
            <Navbar />
            <img src={Header} className='w-full sm:block hidden h-full object-contain' alt="banner" />
            <div className='relative w-full h-full'>
                <img src={HeaderMb} className='w-full sm:hidden block  object-contain' alt="banner" />
                <img src={Text} className='absolute left-1/2 sm:hidden block   top-1/2 transform -translate-x-1/2 -translate-y-1/2' alt="" />
            </div>
            <img src={Welcome} className='w-full sm:block hidden h-full object-contain' alt="welcome" />
            <img src={WelcomeMb} className='w-full sm:hidden block h-full object-cover' alt="welcome" />
            <div className='relative'>

                <img src={week} className='w-full sm:block hidden h-full object-contain' alt="Join" />
                <a className='sm:hidden block ' target="_blank" href="https://members.groundup.studio/join-now/">
                    <img src={weekmb} className='w-full object-contain ' alt="" />
                </a>
                <a className='md:block hidden' target="_blank" href="https://members.groundup.studio/join-now/">
                    <div className='absolute h-[58px] w-[206px] flex cursor-pointer hover:bg-[#d59e92] items-center justify-center top-1/2 transform -translate-y-1/2 right-[15%]  text-[#62361F] text-[25px] bg-[#D8A79D]'>JOIN NOW</div>
                </a>
            </div>
            {/* <img src={JoinMb} className='w-full sm:hidden block h-full object-contain' alt="Join" /> */}
            <img src={Prizes} className='w-full h-full sm:block hidden object-contain' alt="Prizes" />
            <img src={Prizesmb} className='w-full h-full sm:hidden block object-contain' alt="" />

            <div className='relative'>
                <div className='sm:block hidden w-full'>
                    <img src={Download} className='w-full h-full object-cover' alt="Download" />
                    <div className='absolute flex flex-col gap-4 right-[15%] top-1/2 transform -translate-y-1/2'>
                        <div className='flex items-start gap-7'>
                            <img src={Gu} alt="" />
                            <img src={TextDl} alt="" />
                        </div>
                        <div className='flex items-center gap-7'>
                            <a target="_blank" href="https://apps.apple.com/us/app/groundup-studio/id1572605761?itsct=apps_box_badge&itscg=30200">
                                <img src={Ios} alt="" />
                            </a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.vivaleisure.groundupMain&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" >
                                <img src={Gg} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='w-full object-contain sm:hidden block  relative'>
                    <img src={Link} className='w-full object-contain' alt="" />
                    <div className=' absolute justify-center bottom-5 w-full left-1/2 transform -translate-x-1/2 flex items-center gap-7'>
                        <a target="_blank" href="https://apps.apple.com/us/app/groundup-studio/id1572605761?itsct=apps_box_badge&itscg=30200">
                            <img className='min-w-[40%]' src={Ios} alt="" />
                        </a>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.vivaleisure.groundupMain&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" >
                            <img className='min-w-[40%]' src={Gg} alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <img src={Footer} className='w-full cursor-pointer h-full object-contain' alt="footer" />
        </div>
    )
}

export default Movement