import React, { useState } from "react";
import prev from "../../assets/svg/prev.svg";
import next from "../../assets/svg//next.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function StudioSlider(props: any) {
  const { data } = props
  const [slider, setSlider] = useState();
  const settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4,
    arrows: false,
  };



  return (
    <>
      <div className="grid-container about-studio">
        <div className="grid-x">
          <div className="cell about-studio-header">
            <h2>The Studio</h2>
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div className="grid-x">
          <div className="cell">
            <div className="about-carousel-container">
              <div
                className="gallery-carousel flickity-enabled is-draggable"
                // tabIndex={}
                tabIndex={0}
              >
                <div
                  className="flickity-viewport h-[550px] touch-pan-y"
                //   style="height: 550px; touch-action: pan-y;"
                >
                  <div
                    className=""
                  // style="left: 0px; transform: translateX(-125%);"
                  >
                    <Slider {...settings} ref={(c: any) => setSlider(c)}>

                      {data?.data?.attributes?.midCarousels?.[0]?.images?.data?.map(item => (
                        <div
                          className="w-[550px]"
                          //   style="position: absolute; left: 200%;"
                          aria-hidden="true"
                        >
                          <img
                            data-interchange="[assets/img/the-studio-01-new.jpg, small]"
                            alt=""
                            data-resize="6zvt6u-interchange"
                            id="6zvt6u-interchange"
                            src={item?.attributes?.url}
                            data-events="resize"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container about-explore">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            {slider && (
              <div className="carousel-nav">
                <ul className="menu">
                  <li>
                    <a className="prev">
                      <img
                        data-interchange="[assets/svg/prev.svg, small]"
                        alt="Previous"
                        data-resize="i6qc3g-interchange"
                        id="i6qc3g-interchange"
                        src={prev}
                        data-events="resize"
                        onClick={() => slider.slickPrev()}
                      />
                    </a>
                  </li>
                  <li>
                    <a className="next">
                      <img
                        data-interchange="[assets/svg/next.svg, small]"
                        alt="Next"
                        data-resize="yp6g9t-interchange"
                        id="yp6g9t-interchange"
                        src={next}
                        data-events="resize"
                        onClick={() => slider.slickNext()}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="cell medium-6">
            <div className="h3">
              {data?.data?.attributes?.belowAccessCarousel?.customMetadata?.key?.map((item: string, index: number) => (
                <span key={index}>{item}</span>
              ))}
            </div>
          </div>
          <div className="cell medium-6">
            {data?.data?.attributes?.belowAccessCarousel?.customMetadata?.words?.map((item: string, index: number) => (
              <p key={index} className="p2">
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default StudioSlider;
