import React, { useEffect, useState } from "react";

import { Location, useLocation } from "@reach/router";

import Layout from "../components/layout/Layout";

function EnquiryForms() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const studioParam = query.get("studio");

  // State variables
  const [studios] = useState({
    list: ["alexandria", "belconnen", "gungahlin", "yarralumla"],
  });

  const [studiosSelected, setStudiosSelected] = useState<string[]>([
    studioParam || "",
  ]);

  const widgetIds = {
    belconnen: "FLfxhHspznFvr9nCupeo",
    yarralumla: "n9QyTlqtS0b2ntYwrpd9",
    gungahlin: "LWZfGXJq771A8YrILC0c",
    alexandria: "oXq5hxaRuedoFWFGTx39",
  };

  const [clubByProps, setClubByProps] = useState<string>("");

  // Handler - Studio Selection
  const handleCheckStudios = (club: string) => {
    setStudiosSelected([club]);
  };

  // Update studiosSelected when clubByProps changes
  useEffect(() => {
    if (clubByProps) {
      setStudiosSelected([clubByProps]);
    }
  }, [clubByProps]);

  return (
    <Layout>
      <Location>
        {({ location }) => {
          setClubByProps(location?.state?.studio?.toLowerCase());
          return null;
        }}
      </Location>
      <div className="loader" id="loader"></div>
      <div className="member-page-container timetable-container">
        <div className="grid-x ">
          <div className="cell">
            <div className="membership-header">
              <h1 className="h4">Cancel Membership</h1>
            </div>
          </div>
          <div className="flex w-[100vw] mx-auto flex-col">
            <div className="flex md:mx-0 mx-[18px] flex-wrap items-center align-center md:gap-[79px] gap-[20px] mt-[75px]">
              <ul className="menu align-center">
                {studios.list.map((item: string, index: number) => (
                  <li key={index}>
                    <a
                      onClick={() => handleCheckStudios(item)}
                      style={{
                        backgroundColor: studiosSelected.includes(item)
                          ? "rgb(228, 228, 228)"
                          : "#0a0a0a",
                      }}
                      className={`button uppercase tracking-widest font-bold ${
                        studiosSelected.includes(item)
                          ? "amber-text border-b-black "
                          : "blush-text border-b-[#444]"
                      }`}
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="w-[100vw] max-w-[1250px] md:px-0 mt-[10px] flex mb-[80px] flex-col mx-auto bg-[#0a0a0a]"
              style={{ height: studiosSelected[0] ? "auto" : "30vh" }}
            >
              {!studiosSelected[0] ? (
                // Placeholder div
                <p className="text-center mx-auto my-auto text-[14px] h-[30vh] mt-[80px] text-[#e0c3b2] uppercase font-bold">
                  Please select a location.
                </p>
              ) : (
                // Render the iframe
                <div
                  id="join"
                  className="hapana-container w-[100vw] max-w-[1250px]"
                >
                  <iframe
                    src={`https://grow-api.hapana.com/widget/form/${
                      widgetIds[studiosSelected[0]]
                    }`}
                    width="100%"
                    height="1200px" // Set a fixed height here
                    frameBorder="0"
                    allowFullScreen
                    title="Hapana Widget"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default EnquiryForms;
