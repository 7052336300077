// All Imports
import React, { useEffect, useState } from "react";
import { Location } from "@reach/router";
import Layout from "../components/layout/Layout";
import square from "../assets/video/square.mp4";

function TimeTable() {
  // State variables
  const [studios, setStudios] = useState({
    isShow: false,
    list: ["alexandria", "belconnen", "gungahlin", "yarralumla"],
  });
  // Set default location on landing
  const [studiosSelected, setStudiosSelected] = useState<string[]>([
    "alexandria",
  ]);
  const [widgetIds] = useState<{ [key: string]: string }>({
    belconnen: "aTE4MEpReDA1bFdkN3kvaHkyNFludz09",
    yarralumla: "amhmQmdIdldDL3lRdHFpZ3BuOW40Zz09",
    gungahlin: "Q0ZLbEJ6ZWpwVS9tb0cyL3kyTFRWUT09",
    alexandria: "NUxONjNtYnFJUEQzcDNMc0NXYkxKQT09",
  });
  const [clubByProps, setClubByProps] = useState<string>("");

  // Handler - Studio Selection
  const handleCheckStudios = (club: string) => {
    setStudiosSelected([club]);
  };

  // Load Hapana Widget Script
  useEffect(() => {
    const hapanaScriptDiv = document.getElementById("hapana-script");
    if (!hapanaScriptDiv) return;
    hapanaScriptDiv.innerHTML = "";
    const script = document.createElement("script");
    script.src = "https://widget.hapana.com/hapana_widget.js";
    hapanaScriptDiv.appendChild(script);
  }, [studiosSelected]);

  return (
    <Layout>
      <Location>
        {({ location }) => {
          setClubByProps(location?.state?.studio?.toLowerCase());
          return null;
        }}
      </Location>
      <div className="loader" id="loader"></div>
      <div
        style={{ paddingBottom: 0 }}
        className="member-page-container timetable-container"
      >
        <div className="grid-x ">
          <div className="cell">
            <div className="membership-header">
              <h1 className="h4">Timetable</h1>
            </div>
          </div>
          <div className="flex w-[100vw] mx-auto flex-col">
            <div className="flex md:mx-0 mx-[18px] flex-wrap items-center align-center md:gap-[79px] gap-[20px] mt-[75px]">
              <ul className="menu align-center">
                {studios.list.map((item: string, index: number) => (
                  <li key={index}>
                    <a
                      onClick={() => handleCheckStudios(item)}
                      style={{
                        backgroundColor: studiosSelected.includes(item)
                          ? "rgb(228, 228, 228)"
                          : "#0a0a0a",
                      }}
                      className={`button uppercase tracking-widest font-bold uppercase ${
                        studiosSelected.includes(item)
                          ? "amber-text border-b-black "
                          : "blush-text border-b-[#444]"
                      }`}
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-[100vw] max-w-[1250px] md:px-0 mt-[10px] flex mb-[80px] border-y border-y-white flex-col mx-auto">
              <div id="timetable" className="hapana-container">
                <hapana-widget
                  data-type="classes"
                  widget-id={widgetIds[studiosSelected[0]]}
                  instructor-id=""
                  session-type="Classes"
                ></hapana-widget>
                <script src="https://widget.hapana.com/hapana_widget.js"></script>
              </div>
            </div>
          </div>

          <div className="flex w-full h-[417px] items-center">
            <div className="md:w-[50%] w-full h-full bg-white flex items-center justify-center flex-col">
              <div>
                <h1 className="panadol uppercase banner-text-content text-black">
                  <h3>
                    not a <br /> member?
                  </h3>
                </h1>
                <h4 className="mt-[40px] flex  items-center  gap-4 text-[35px] text-black uppercase">
                  <span>join now </span>{" "}
                  <img
                    src="https://groundup.studio/timetable/assets/svg/button1-arrow-black.svg"
                    alt=""
                  />
                </h4>
              </div>
            </div>
            <div className="w-[50%] md:flex hidden bg-white h-full items-center justify-center flex-col">
              <video
                autoPlay
                loop
                muted
                src={square}
                className="w-full object-cover h-full"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TimeTable;
