import React, { useState } from "react";

import { Link } from "gatsby";

import { useLocation } from "@reach/router";

import logo from "../assets/svg/logo.svg";
import Sidebar from "./mobile/Sidebar";

function Navbar() {
  const [navLinks, setNavLinks] = useState<object>({
    list: ["About", "Classes", "Timetable", "Studios"],
    active: "",
  });
  const router = useLocation();

  return (
    <div className="bg-[#E0C3B2] w-full  top-0 z-10">
      <div className="mx-auto max-w-[1344px] flex justify-between px-[2.8125rem] pt-[1.75rem] pb-[1.75rem] ">
        <div>
          <Link to="/">
            <img src={logo} className="w-[140px] sm:w-[230px]" alt="logo" />
          </Link>
        </div>
        <div className="top-bar-right">
          <div className="xxx:flex hidden">
            <ul className="menu ">
              {navLinks.list.map((item: string, index: number) => (
                <li
                  className={`${
                    router.pathname == "/" + item.toLocaleLowerCase()
                      ? "is-active-link"
                      : ""
                  } uppercase  `}
                >
                  <a className="amber-text" href={item.toLocaleLowerCase()}>
                    {item}
                  </a>
                </li>
              ))}
              <li>
                <a
                  className="uppercase amber-text"
                  href="https://www.hiitrepublic.com.au/"
                  target="_blank"
                >
                  HIIT Republic
                </a>
              </li>
              <li>
                <a
                  className="uppercase amber-text"
                  href="https://www.clublime.com.au/"
                  target="_blank"
                >
                  Club Lime
                </a>
              </li>
              {/* <li>
                <a className="uppercase" href="/movement" >
                  CHALLENGE
                </a>
              </li> */}
              <li>
                <a
                  className="button uppercase tracking-widest font-bold amber-text"
                  href="/join-now"
                >
                  Join Now
                </a>
              </li>
              {/* <li>
                <a
                  className="button hollow uppercase tracking-wider font-bold amber-text"
                  href="/timetable"
                >
                  Log In
                </a>
              </li> */}
            </ul>
          </div>
          <div className="xxx:hidden block">
            <Sidebar />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
