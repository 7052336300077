import React from "react";
import HomeNewMemberShip from "../../assets/img/home-membership-new.jpg"
function IntoStudio(props) {
  const { data } = props
  return (
    <div className="home-member-container home">
      <div className="grid-container full home-member">
        <div className="grid-x grid-margin-x">
          <div className="cell medium-6">
            <div
              className="bg-image home-member-img"
              data-interchange="[assets/img/home-membership-new.jpg, small]"
              data-resize="fret0x-interchange"
              id="fret0x-interchange"
              style={{ backgroundImage: `url(${data?.data?.attributes?.step2?.background?.data?.attributes?.url})` }}
              data-events="resize"
            ></div>
          </div>
          <div className="cell medium-6 content">
            <div className="h2">
              <span>{data?.data?.attributes?.step2?.customMetadata?.[0]}</span>
              <span>{data?.data?.attributes?.step2?.customMetadata?.[1]}</span>
              <span>{data?.data?.attributes?.step2?.customMetadata?.[2]}</span>
              <span>{data?.data?.attributes?.step2?.customMetadata?.[3]}</span>
              <span>{data?.data?.attributes?.step2?.customMetadata?.[4]}</span>
              <span>{data?.data?.attributes?.step2?.customMetadata?.[5]}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntoStudio;
