import React from "react";
function ReformerYoga(props) {
  const { data } = props;

  return (
    <div id="classes" className="home-classes-container home">
      <div
        className="grid-container home-classes-bg-image"
        data-interchange="[assets/img/home-classes-new.png, small]"
        data-resize="6e0a8l-interchange"
        id="6e0a8l-interchange"
        style={{
          backgroundImage: `url(${data?.data?.attributes?.step3?.background?.data?.attributes?.url})`,
        }}
        data-events="resize"
      >
        <div className="grid-x">
          <div className="cell">
            <div className="home-classes-content">
              <div className="h1">
                <span>
                  {data?.data?.attributes?.step3?.customMetadata?.[0]}
                </span>
                <span>
                  {data?.data?.attributes?.step3?.customMetadata?.[1]}
                </span>
                <span>
                  {data?.data?.attributes?.step3?.customMetadata?.[2]}
                </span>
                <span>
                  {data?.data?.attributes?.step3?.customMetadata?.[3]}
                </span>
                <span>
                  {data?.data?.attributes?.step3?.customMetadata?.[4]}
                </span>
              </div>
              <div className="home-classes-button">
                <a
                  href="classes"
                  className="button blush-text large transparent"
                >
                  Classes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReformerYoga;
