import React from "react";
import Layout from "../components/layout/Layout";

function SuspensionForms() {
  return (
    <Layout>
      <div className="loader" id="loader"></div>
      <div className="member-page-container timetable-container">
        <div className="grid-x ">
          <div className="cell">
            <div className="membership-header">
              <h1 className="h4 pb-[25px]">Suspend Membership</h1>
            </div>
          </div>
          <div className="flex w-[100vw] mx-auto flex-col">
            <div className="w-[100vw] max-w-[1250px] md:px-0 mt-[10px] flex mb-[80px] flex-col mx-auto bg-[#0a0a0a]">
              <div
                id="join"
                className="hapana-container w-[100vw] max-w-[1250px]"
              >
                <iframe
                  src="https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fgrow-api.hapana.com%2Fwidget%2Fform%2Fz6IQYWDzlS54gLyf9Hna&data=05%7C02%7Cdietrich.hergesheimer%40vivalabs.com.au%7C638ae80f867c479d91fe08dccbe988a8%7C2e612d1fe21e491cae089a0c9a843ee6%7C0%7C0%7C638609451141253419%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=gPKuY8FF3YUvKxro1wIM1Npo8a06KYcYyTi%2FcRBUK04%3D&reserved=0"
                  width="100%"
                  height="1750px" // Set a fixed height here
                  frameBorder="0"
                  allowFullScreen
                  title="Hapana Widget"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SuspensionForms;
