import React, { useRef, useState } from "react";
import Accordition from "./Accordition";

function PilatesMenu(props: any) {
  const { data } = props

  return (
    <div className="grid-container classes-accordion">
      <div className="grid-x">
        <div className="cell">
          <ul
            id="className-accordion"
            className="accordion"
            data-accordion="9q2t77-accordion"
            data-multi-expand="true"
            data-allow-all-closed="true"
            role="tablist"
          >
            {data?.data?.attributes?.classes?.map((item: object, index: number) => (
              <Accordition key={index} item={item} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PilatesMenu;
