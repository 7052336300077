import React from "react";
function TheStudio(props) {
  const { data } = props

  return (
    <div id="studio" className="home-studio-container home">
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell medium-6">
            <div
              className="bg-image home-studio-img"
              data-interchange="[assets/img/home-studio-new.jpg, small]"
              data-resize="4jo2rm-interchange"
              id="4jo2rm-interchange"
              style={{ backgroundImage: `url(${data?.data?.attributes?.step4?.background?.data?.attributes?.url})` }}

              data-events="resize"
            ></div>
          </div>
          <div className="cell medium-6 text-content">
            <div className="h1">
              <span>{data?.data?.attributes?.step4?.title}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TheStudio;
