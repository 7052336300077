import React, { useRef, useState } from 'react'

function Accordition(props: any) {
    const [selected, setSelected] = useState<boolean>(false)
    const ref = useRef<any>()

    const { item } = props

    return (
        <li className={`accordion-item ${selected ? '' : 'is-active'} `}        >
            <div
                onClick={() => setSelected(!selected)}
                className="accordion-title select-none cursor-pointer"
                aria-controls="xdhx7g-accordion"
                role="tab"
                id="xdhx7g-accordion-label"
            >
                <h3 className='text-[50px]'>{item?.title}</h3>
            </div>
            <div
                ref={ref}
                className={`transition-all ${selected ? `opacity-100 lg:h-[620px] h-auto transition-all` : 'h-0 opacity-0 transition-all'} cursor-pointer`}
                data-tab-content=""
                role="tabpanel"
                aria-labelledby="xdhx7g-accordion-label"
            >
                <div className="grid-x">
                    <div className="cell small-order-2 large-6 large-order-1">
                        <div className="accordion-content-info">
                            <p className="p2">
                                {item?.description}
                            </p>
                        </div>
                        <div className="accordion-content-fieldset">
                            <fieldset>
                                <legend>
                                    <p className="p2">{item?.bestThingTitle}</p>
                                </legend>
                                {item?.bestThing?.map(item => (
                                    <>
                                        <input
                                            type="radio"
                                            name="style"
                                            value="Toning"
                                            id={item?.value}
                                        />
                                        <label >{item?.value}</label>
                                        <br />
                                    </>
                                ))}

                            </fieldset>
                        </div>
                    </div>
                    <div className="cell small-order-1 large-6 large-order-2">
                        <div
                            className="bg-image accordion-img"
                            data-resize="dt2x2q-interchange"
                            data-events="resize"
                            style={{ backgroundImage: `url(${item?.media?.data?.attributes?.url})` }}
                        >
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default Accordition