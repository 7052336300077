import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';

function Access() {
    const [password, setPassword] = useState<string>('');

    // const handleSubmit = (e: Event) => {
    //     e.preventDefault();
    //     if (password === '123123') {
    //         navigate(`/`);
    //         window.localStorage.setItem('groundup', 'token');
    //     } else {
    //         setPassword('')
    //     }
    // };
    return (
        <div className="text-white w-full">
            <div className="flex justify-center mt-[30px]"></div>
            <div className="md:w-[473px] w-[90%] md:px-0  absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] mx-auto">
                <h1 className="text-[50px] text-slate-400 font-bold text-center mb-[45px]">View Page</h1>
                <div className="flex flex-col items-center justify-center  gap-y-[18px]">
                    <input value={password} onChange={(e) => setPassword(e.target.value)} placeholder='password' className='w-full rounded-md' type="password" />
                    <div className='flex justify-end w-full' onClick={handleSubmit}>
                        <button className='w-[120px] h-[45px] rounded-md bg-slate-500 text-white cursor-pointer'>Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Access;
