import React, { useState } from "react";

function Sidebar() {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div>
      <div onClick={() => setOpen(true)} className="cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="37"
          height="14"
          viewBox="0 0 37 14"
          fill="none"
          className="mdl-js"
        >
          <path d="M0 1H37" stroke="white" stroke-width="2" />
          <path d="M0 13H37" stroke="white" strokeWidth="2" />
        </svg>
      </div>
      <div
        className={`off-canvas position-top is-transition-overlap ${
          open ? "is-open" : ""
        } `}
        id="offcanvas-menu"
        data-off-canvas="z075wc-off-canvas"
        data-transition="overlap"
        aria-hidden="false"
      >
        <div className="grid-container">
          <div className="grid-y">
            <div className="cell small-1">
              <div className="grid-x">
                <div className="cell">
                  <div className="title-bar flex ">
                    <div className="title-bar-left">
                      <div className="title-bar-title">
                        <a href="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 222 41"
                            className="mdl-js"
                          >
                            <path
                              fill="#fff"
                              d="M19.815 31.657c-1.546 1.026-3.357 1.437-5.836 1.437C2.208 33.094 0 25.494 0 18.341 0 11.188 2.306 3.589 14.763 3.589c10.692 0 14.42 4.828 14.42 9.97h-9.466c-.294-1.924-1.472-3.264-4.954-3.264-4.855 0-5.247 3.8-5.247 8.046 0 4.246.343 8.092 5.199 8.092 2.6 0 4.66-1.788 5.002-4.47h-5.541v-6.17h15.057v24.412h-7.484c-2.006 0-1.934-2.37-1.934-2.37v-6.178zM125.481 4.037v15.826L116.21 4.037H105.372v16.047c0 4.56-1.03 5.902-4.661 5.902-3.58 0-4.658-1.34-4.658-5.902V4.037h-9.465v10.672c-.88-5.911-4.375-11.12-15.002-11.12-9.93 0-13.614 4.582-14.76 10.007-.098-4.077-1.56-9.56-13.335-9.56H31.229v28.61h9.417V22.052l6.818 10.596h11.723l-9.073-10.46c3.486-.597 5.492-2.637 6.298-5.217a28.32 28.32 0 00-.032 1.373c0 7.197 2.403 14.751 15.205 14.751 10.831 0 14.253-5.407 15.051-11.403.477 7.786 4.604 11.403 14.077 11.403 10.153 0 14.176-4.111 14.176-13.008v-2.973l.049.065 9.564 15.467h10.496V4.037h-9.517zM43.147 17.224h-2.5v-6.348h2.5c3.336 0 4.17 1.116 4.17 3.218 0 1.923-.784 3.13-4.17 3.13zm28.438 8.985c-5.345 0-5.69-3.622-5.69-7.868 0-4.247.345-7.824 5.69-7.824 5.397 0 5.69 3.577 5.69 7.868 0 4.202-.295 7.824-5.69 7.824zM136.898 4.037h12.606c10.104 0 14.861 5.498 14.861 14.127 0 8.582-5.1 14.483-15.352 14.483h-12.115V4.037zm10.939 21.77c4.562 0 7.26-1.073 7.26-7.69 0-6.482-2.453-7.241-7.063-7.241h-1.717v14.93h1.52zM196.398 4.037h11.919C220.971 4.037 222 9.892 222 14.274c0 4.335-.686 10.104-13.291 10.104h-2.893v8.269h-9.418V4.037zm12.164 13.68c3.138 0 3.924-1.653 3.924-3.443 0-1.832-.834-3.398-3.924-3.398h-2.746v6.84h2.746z"
                            />
                            <path
                              fill="#fff"
                              d="M185.084 4.037v16.047c0 4.56-1.03 5.902-4.661 5.902-3.58 0-4.658-1.34-4.658-5.902V4.037h-.008L169.561.018 160.523 0l5.722 4.037h.051v16.047c0 8.897 4.021 13.008 14.125 13.008 10.153 0 14.176-4.111 14.176-13.008V4.037h-9.513z"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div
                      onClick={() => setOpen(false)}
                      className="title-bar-right  flex justify-end cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        className="mdl-js"
                      >
                        <path
                          d="M1 26L26 0.999999"
                          stroke="white"
                          strokeWidth="2"
                        />
                        <path
                          d="M26 26L0.999998 1"
                          stroke="white"
                          strokeWidth="2"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cell small-10 flex-container">
              <ul className="menu vertical align-self-middle amber-text">
                <li>
                  <a href="about">About</a>
                </li>
                <li>
                  <a href="classes">Classes</a>
                </li>
                <li>
                  <a href="/timetable">Timetable</a>
                </li>
                <li>
                  <a href="studios">Studios</a>
                </li>
                <li>
                  <a href="https://www.hiitrepublic.com.au/" target="_blank">
                    HIIT Republic
                  </a>
                </li>
                <li>
                  <a href="https://www.clublime.com.au/" target="_blank">
                    Club Lime
                  </a>
                </li>
                {/* <li><a href="/movement">Challenge</a></li> */}
              </ul>
            </div>
            <div className="cell small-1 align-self-bottom mt-[-100px]">
              <div className="button-group">
                <a className="button" href="/join-now">
                  Join Now
                </a>
                {/* <a className="button hollow" href="/timetable">
                  {/* Login */}
                {/* </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
