import React from "react";

import arrowDownWhite from "../../assets/svg/arrow-down-white.svg";
import ScrollToElm from "../../common/scrollEffect/ScrollToElm";

function VideoContainer(data) {
  const bannerLink = data?.data?.data?.attributes?.bannerURL;
  const isVideo =
    data?.data?.data?.attributes?.introVideo?.data?.attributes?.url?.slice(
      -3
    ) == "mp4";
  const isVideoInMobile =
    data?.data?.data?.attributes?.bannerMobile?.data?.attributes?.url?.slice(
      -3
    ) == "mp4";

  return (
    <div className=" w-full my-0 ">
      <a href={bannerLink || "#"}>
        <div className="md:flex hidden h-full mx-auto">
          {isVideo && (
            <video
              autoPlay
              src={
                data?.data?.data?.attributes?.introVideo?.data?.attributes?.url
              }
              muted
              loop
              className=" w-[100vw] object-cover overflow-hidden"
            ></video>
          )}
          {!isVideo && (
            <img
              src={
                data?.data?.data?.attributes?.introVideo?.data?.attributes?.url
              }
              className=" w-[100vw] object-bottom overflow-hidden object-cover"
              alt=""
            />
          )}
        </div>
      </a>
      {/* Mobile */}
      <a href={bannerLink || "#"}>
        <div className="md:hidden block ">
          {isVideoInMobile && (
            <video
              autoPlay
              src={
                data?.data?.data?.attributes?.bannerMobile?.data?.attributes
                  ?.url
              }
              muted
              loop
              className="h-[100vh] w-[100vw] object-cover overflow-hidden"
            ></video>
          )}
          {!isVideoInMobile && (
            <img
              src={
                data?.data?.data?.attributes?.bannerMobile?.data?.attributes
                  ?.url
              }
              className="h-[100vh] w-[100vw] object-left-bottom overflow-hidden"
              alt=""
            />
          )}
        </div>
      </a>
      <div
        onClick={() => ScrollToElm("about")}
        className="absolute bottom-[7rem] left-0 right-0 m-auto w-[1rem] h-[2.3125rem] "
      >
        <img src={arrowDownWhite} alt="Down" />
      </div>
    </div>
  );
}

export default VideoContainer;
