import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout/Layout";
import useFetch from "../hooks/useFetch";

function Studios() {
  const { data } = useFetch({
    params: "studios",
    populate: "background,access",
  });

  return (
    <Layout>
      <div className="member-page-container">
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="membership-header">
                <h1 className="studios-title ">Studios </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="card-container">
          <div className="coming-soon hidden">
            <h1>COMING SOON</h1>
            <div className="grid-x grid-margin-x">
              <div className="cell small-10 p2">
                <p>
                  Want to know when we launch? Register your interest below to
                  be the first to know
                </p>
              </div>
            </div>
            <div className="grid-x grid-margin-x">
              <div className="grid-x grid-margin-x">
                <div className="cell medium-3 large-3">
                  <input
                    type="text"
                    value=""
                    name="FNAME"
                    className="required"
                    id="mce-FNAME"
                    placeholder="FIRST NAME"
                    required
                  />
                </div>
                <div className="cell medium-3 large-3">
                  <input
                    type="text"
                    value=""
                    name="LNAME"
                    className="required"
                    id="mce-LNAME"
                    placeholder="LAST NAME"
                    required
                  />
                </div>

                <div className="cell medium-3 large-4"></div>
                <div id="mce-responses" className="clear"></div>
              </div>
              <div className="grid-x grid-margin-x">
                <p className="cell small-10 p2 mc-status"></p>
              </div>
            </div>
          </div>
          <div className="card-grid studio-cards">
            {data?.data?.map((item: object, idx: number) => (
              <div key={idx} className="card-cell cursor-pointer">
                <div className="card-new">
                  <div className="cards">
                    <img
                      className="card-image"
                      src={item?.attributes?.background?.data?.attributes?.url}
                    />
                  </div>
                  <div className="card-section">
                    <div className="pos-box">
                      <div className="card-info location">
                        <img src="https://viva-groundup-cms.s3.ap-southeast-2.amazonaws.com/pin_afa97eb9e4.png?updated_at=2023-09-01T00:19:40.250Z" />
                        <div className="w-[174px] text-center mx-auto">
                          {" "}
                          {item?.attributes?.address}
                        </div>
                      </div>
                      <div
                        style={{ left: 0 }}
                        className="card-info  exercise mx-auto  w-full"
                      >
                        <img src="https://viva-groundup-cms.s3.ap-southeast-2.amazonaws.com/plus_2d90fc26a5.png?updated_at=2023-09-01T00:19:40.182Z" />
                        {item?.attributes?.access?.map((item) => (
                          <div>{item?.value} </div>
                        ))}
                      </div>
                      <Link
                        className={
                          item?.attributes?.title === "BRADDON"
                            ? "button hollow is-hidden"
                            : "button hollow"
                        }
                        to="/timetable"
                        state={{ studio: item?.attributes?.title }}
                      >
                        <div className="card-info cursor-pointer timetable">
                          <img src="https://viva-groundup-cms.s3.ap-southeast-2.amazonaws.com/timetable_aa7579cf74.png?updated_at=2023-09-01T00:19:40.199Z" />
                          <div>
                            <span>View Timetable</span>{" "}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="btn-container">
                    <Link
                      className={
                        item?.attributes?.title === "BRADDON"
                          ? "button hollow is-hidden"
                          : "button hollow"
                      }
                      to={`/join-now?studio=${item?.attributes?.title?.toLowerCase()}`}
                    // state={{ studio: item?.attributes?.title?.toLowerCase() }}
                    >
                      Join Now
                    </Link>
                    <a
                      href={`/sale-lead-form-${item?.attributes?.title?.toLowerCase()}`}
                      style={{
                        marginLeft:
                          item?.attributes?.title === "BRADDON" ? "auto" : "",
                        marginRight:
                          item?.attributes?.title === "BRADDON" ? "auto" : "",
                      }}
                      className="button hollow"
                    >
                      Enquire
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Studios;
