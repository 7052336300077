import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";

function Layout(props: any) {
  return (
    <div id="Layout" className="w-full relative">
      <Navbar />
      {props.children}
      <Footer />
    </div>
  );
}
export default Layout;
