import React from "react";

import BlackArrDown from "../../assets/svg/arrow-down-black.svg";
import ScrollToElm from "../../common/scrollEffect/ScrollToElm";

function ReformerNMat(props: any) {
  const { data } = props;

  return (
    <div className="about-header-container bg-[#693927]">
      <div className="grid-container about-banner">
        <div className="grid-x grid-margin-x">
          <div className="cell medium-6">
            <div
              className="bg-image banner-image"
              id="8ogfzz-interchange"
              style={{
                backgroundImage: `url(${data?.data?.attributes?.topBanner?.[0]?.background?.data?.attributes?.url})`,
              }}
              data-events="resize"
            ></div>
          </div>
          <div className="cell medium-6 banner-text">
            <div className="grid-x">
              <div className="cell">
                <div className="studio-header-content h2 blush-text">
                  {data?.data?.attributes?.topBanner?.[0]?.customMetadata?.map(
                    (item: string, index: number) => (
                      <span key={index}>{item}</span>
                    )
                  )}
                </div>
              </div>
              <a
                className="arrow-down"
                id="v32njb-smooth-scroll"
                onClick={() => ScrollToElm("classes")}
              >
                <img
                  data-interchange="[assets/svg/arrow-down-black.svg, small]"
                  data-resize="dcugfw-interchange"
                  id="dcugfw-interchange"
                  src={BlackArrDown}
                  data-events="resize"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReformerNMat;
