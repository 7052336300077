import React from "react";
import Layout from "../components/layout/Layout";

function CancellationForms() {
  return (
    <Layout>
      <div className="loader" id="loader"></div>
      <div className="member-page-container timetable-container sus-can-containers">
        <div className="grid-x ">
          <div className="cell">
            <div className="membership-header">
              <h1 className="h4 pb-[25px]">Cancel Membership</h1>
            </div>
          </div>
          <div className="flex w-[100vw] mx-auto flex-col">
            <div className="w-[100vw] max-w-[1250px] md:px-0 mt-[10px] flex mb-[80px] flex-col mx-auto bg-[#0a0a0a]">
              <div
                id="join"
                className="hapana-container w-[100vw] max-w-[1250px]"
              >
                <iframe
                  src="https://grow-api.hapana.com/widget/form/p6KuEpjzig8HtUIRHQvv"
                  scrolling="no"
                  width="100%"
                  height="1200px" // Set a fixed height here
                  frameBorder="0"
                  allowFullScreen
                  title="Hapana Widget"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CancellationForms;
