import React, { useEffect, useState } from "react";

import { HeadFC } from "gatsby";
import { Helmet } from "react-helmet";

import { Router, useLocation } from "@reach/router";

import PrivateRoute from "../components/PrivateRoute";
import { SEO } from "../components/SEO";
import useFetch from "../hooks/useFetch";
import AboutPage from "../routes/About";
import Access from "../routes/Access";
import ClassesPage from "../routes/Classes";
import HomePage from "../routes/index";
import IpadCheckin from "../routes/IpadCheckin";
import Movement from "../routes/Movement";
import StudioDetail from "../routes/StudioDetail";
import Studios from "../routes/Studios";
import TimeTable from "../routes/Time-table";
import IpadCheckinHiit from "../routes/IpadCheckinHiit";
import FreeClass from "../routes/FreeClass";
import JoinNow from "../routes/JoinNow";
import SuspensionForms from "../routes/SuspensionForms";
import CancellationForms from "../routes/CancellationForms";
import EnquiryForms from "../routes/EnquiryForms";

const IndexPage = () => {
  const location = useLocation();
  const [modal, setModal] = useState<boolean>(false);
  useEffect(() => {
    window.sessionStorage.getItem("modal") !== "false" &&
      window.sessionStorage.setItem("modal", "true");
  }, []);

  useEffect(() => {
    window.sessionStorage.getItem("modal") == "true"
      ? setModal(true)
      : setModal(false);
  }, [modal, location.pathname]);

  useEffect(() => {
    if (location.pathname == "/") {
      window.sessionStorage.setItem("modal", "true");
      setModal(true);
    }
  }, [location.pathname]);

  return (
    <>
      {/* {modal && (
        <div style={{ backgroundColor: 'rgba(0,0,0,0.8)' }} className="fixed left-0 top-0 w-full h-full  z-[999999] ">
          <div className="overflow-hidden fixed z-30 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <div onClick={() => { window.sessionStorage.setItem('modal', 'false'); setModal(false) }}
              className="underline text-white cursor-pointer text-[20px] text-center mb-[10px]">Close</div>
            <img onClick={() => window.open('https://members.groundup.studio/join-now', '_blank', 'noopener,noreferrer')}
              className="w-[335px]  rounded-[10px] h-auto" src={sale} />
          </div>
        </div>
      )} */}

      <Helmet>
        <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5FHZF4R4"
            height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>`}</noscript>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5FHZF4R4');
        `}</script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-M4HF3B83D0"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-M4HF3B83D0');
            `}
        </script>
      </Helmet>
      <Router basepath="/">
        {/* Protected Routes */}
        <PrivateRoute path="/" component={HomePage} />
        <PrivateRoute path="/about" component={AboutPage} />
        <PrivateRoute path="/classes" component={ClassesPage} />
        <PrivateRoute path="/studios" component={Studios} />
        <PrivateRoute path="/:id" component={StudioDetail} />
        <PrivateRoute path="/free-class" component={FreeClass} />
        <PrivateRoute path="/timetable" component={TimeTable} />
        <PrivateRoute path="/ipad-checkin" component={IpadCheckin} />
        <PrivateRoute path="/movement" component={Movement} />
        <PrivateRoute path="/ipad-checkin-hiit" component={IpadCheckinHiit} />
        <PrivateRoute path="/join-now" component={JoinNow} />
        <PrivateRoute path="/suspend-membership" component={SuspensionForms} />
        <PrivateRoute path="/cancel-membership" component={CancellationForms} />
        <PrivateRoute path="/enquiry" component={EnquiryForms} />
        {/* Public Routes */}
        {/* <Login path="/login" />
        <Register path="/register" />
      <NotFoundPage path="*" /> */}
        <Access path="/access" />
      </Router>
      <div id="hapana-script"></div>
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => {
  const path = useLocation();
  const [content, setContent] = useState<string>("");
  const { data: seo } = useFetch({ params: "global", populate: "seo" });

  useEffect(() => {
    if (path.pathname.replace(/\//g, "") == "") {
      setContent(seo?.data?.attributes?.seo?.[0]);
    } else {
      setContent(
        seo?.data?.attributes?.seo?.find(
          (item) => item?.key == path.pathname.replace(/\//g, "")
        )
      );
    }
  }, [seo, path.pathname]);

  return <SEO title={"GROUND UP"} description={content?.value} />;
};
