import { Link } from "gatsby";
import React from "react";
function PilatesReformer(props: any) {
  const { data } = props

  return (
    <div className="classes-header-container">
      <div className="grid-container classes-banner">
        <div className="grid-x ">
          <div className="cell medium-6 banner-text">
            <div className="classes-header-content h2">
              {data?.data?.attributes?.topBanner?.customMetadata?.map((item: string, index: number) => (
                <span key={index}>{item}</span>
              ))}
              <div className="classes-btn">
                <Link to="/timetable">
                  <button
                    className="button large transparent"
                  >
                    Book A Class
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="cell medium-6">
            <div
              className="bg-image banner-image"
              data-interchange="[assets/img/classes-hero-new.png, small]"
              data-resize="mclcqs-interchange"
              id="mclcqs-interchange"
              style={{ backgroundImage: `url(${data?.data?.attributes?.topBanner?.background?.data?.attributes?.url})` }}
              data-events="resize"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PilatesReformer;
