import React, { useEffect, useState } from "react";
import { Location } from "@reach/router";
import Layout from "../components/layout/Layout";
import { useLocation } from "@reach/router";

function JoinNow() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const studioParam = query.get("studio");
  // State variables
  const [studios, setStudios] = useState({
    list: ["alexandria", "belconnen", "gungahlin", "yarralumla", "dickson"],
  });

  // Set default location on landing
  const [studiosSelected, setStudiosSelected] = useState<string[]>([
    studioParam || "",
  ]);

  const [widgetIds] = useState<{ [key: string]: string }>({
    belconnen: "aTE4MEpReDA1bFdkN3kvaHkyNFludz09",
    yarralumla: "amhmQmdIdldDL3lRdHFpZ3BuOW40Zz09",
    gungahlin: "Q0ZLbEJ6ZWpwVS9tb0cyL3kyTFRWUT09",
    alexandria: "NUxONjNtYnFJUEQzcDNMc0NXYkxKQT09",
    dickson: "TUxseFdsSUZUWFk3WVdoUUd1UWxoZz09",
  });

  const [clubByProps, setClubByProps] = useState<string>("");

  // Handler - Studio Selection
  const handleCheckStudios = (club: string) => {
    setStudiosSelected([club]);
  };

  // Update studiosSelected when clubByProps changes
  useEffect(() => {
    if (clubByProps) {
      setStudiosSelected([clubByProps]);
    }
  }, [clubByProps]);

  // Load Hapana Widget Script
  useEffect(() => {
    const hapanaScriptDiv = document.getElementById("hapana-script");
    if (!hapanaScriptDiv) return;
    hapanaScriptDiv.innerHTML = "";
    const script = document.createElement("script");
    script.src = "https://widget.hapana.com/hapana_widget.js";
    hapanaScriptDiv.appendChild(script);
  }, [studiosSelected]);

  return (
    <Layout>
      <Location>
        {({ location }) => {
          setClubByProps(location?.state?.studio?.toLowerCase());
          return null;
        }}
      </Location>
      <div className="loader" id="loader"></div>
      <div className="member-page-container timetable-container">
        <div className="grid-x ">
          <div className="cell">
            <div className="membership-header">
              <h1 className="h4">Join Now</h1>
            </div>
          </div>
          <div className="flex w-[100vw] mx-auto flex-col">
            <div className="flex md:mx-0 mx-[18px] flex-wrap items-center align-center md:gap-[79px] gap-[20px] mt-[75px]">
              <ul className="menu align-center">
                {studios.list.map((item: string, index: number) => (
                  <li key={index}>
                    <a
                      onClick={() => handleCheckStudios(item)}
                      style={{
                        backgroundColor: studiosSelected.includes(item)
                          ? "rgb(228, 228, 228)"
                          : "#0a0a0a",
                      }}
                      className={`button uppercase tracking-widest font-bold ${
                        studiosSelected.includes(item)
                          ? "amber-text border-b-black "
                          : "blush-text border-b-[#444]"
                      }`}
                    >
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-[100vw] max-w-[1250px] md:px-0 mt-[10px] flex mb-[80px] flex-col mx-auto bg-[#0a0a0a]">
              {!studiosSelected[0] ? (
                // Placeholder div
                <p className="text-center mx-auto my-auto text-[14px] h-[30vh] mt-[80px] text-[#e0c3b2] uppercase font-bold">
                  Please select a location.
                </p>
              ) : (
                // Render the widget
                <div
                  id="join"
                  className="hapana-container w-[100vw] max-w-[1250px]"
                >
                  <hapana-widget
                    data-type="packages"
                    widget-id={widgetIds[studiosSelected[0]]}
                    package-id=""
                    variant=""
                  ></hapana-widget>
                  <script src="https://widget.hapana.com/hapana_widget.js"></script>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default JoinNow;
